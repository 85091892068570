<template>
  <BTableCard
    :searchActive="true"
    :newAddActive="true"
    :totalActive="true"
    :backActive="backTo"
    :show="show"
    :icon="'fad fa-users'"
    :title="'Üyeler'"
    :total="total"
    @handlerAddNew="handlerAddNew"
    @handlerBackTo="handlerBackTo"
  >
    <template v-slot:body>
      <div class="p-1">
        <transition mode="out-in">
          <router-view @total="total = $event" @show="show = $event" @backTo="backTo = $event" />
        </transition>
      </div>
    </template>
  </BTableCard>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api';
import BTableCard from '@/components/cards/BTableCard.vue';
import { useRouter } from '@/libs/utils';

export default defineComponent({
  components: {
    BTableCard,
  },
  setup() {
    const expo = {};
    const { route, router } = useRouter();
    expo.show = ref(false);
    expo.backTo = ref(false);
    expo.total = ref(0);

    expo.handlerAddNew = () => {
      expo.total.value = 0;
      expo.backTo.value = true;
      route.value.name != 'uye-ekle' && router.push({ name: 'uye-ekle' });
    };

    expo.handlerBackTo = () => {
      expo.backTo.value = false;
      router.push({ name: 'uye-listele' });
    };
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
table.vgt-table {
  font-size: 14px;
  thead {
    background-color: rgba(#000, 0.07);
  }
  td {
    padding: 3px 0px 0px 5px !important;
    vertical-align: middle !important;
  }
}
</style>
